import React from "react";
import {
  decodeToken,
  deleteToken,
} from "../../../../utils/authentication/jwtAuth";
import { formatPhoneNumber } from "../../../../utils/helpers/data-processing/formatPhoneNumber";
import mixpanel from "../../../../mixpanel";

const NavMenuExpanded = ({
  menuRef,
  isMenuOpen,
  loggedIn,
  setLoggedIn,
  setIsMenuOpen,
  customNavigation,
}) => {
  const menuElements = [
    { text: "About", linkTo: "/#service-overview" },
    { text: "Area of Service", linkTo: "/service-area" },
    { text: "Seller Payment", linkTo: "/pay-seller" },
    { text: "Item Inspection", linkTo: "/item-inspection" },
  ];

  const handleLogout = () => {
    deleteToken();
    setLoggedIn(false);
    handleMenuItemClick("/");
    mixpanel.track("Clicked Logout", {});
  };

  const handleMenuItemClick = (linkTo) => {
    setIsMenuOpen(false);
    customNavigation(linkTo);
    mixpanel.track("Clicked Menu Item", { itemClicked: linkTo });
  };

  return (
    <div
      ref={menuRef}
      className={`${
        isMenuOpen ? "block" : "hidden"
      } absolute top-full right-0 w-64 z-50 bg-white divide-y divide-gray-100 rounded-lg shadow`}
      id="mobile-menu-2"
    >
      {loggedIn ? (
        <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
          <div>Logged in as:</div>
          <div className="font-medium truncate">
            {formatPhoneNumber(decodeToken().phoneNumber)}
          </div>
        </div>
      ) : (
        <ul
          className="lg:hidden py-2 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownInformationButton"
        >
          <li className={`mb-2`}>
            <a
              href={"/login"}
              className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={() =>
                mixpanel.track("Clicked Menu Item", {
                  itemClicked: "Login",
                })
              }
            >
              Log In
            </a>
          </li>
        </ul>
      )}
      <ul
        className="py-2 text-sm text-gray-700 dark:text-gray-200"
        aria-labelledby="dropdownInformationButton"
      >
        {loggedIn && (
          <li className={`mb-2`}>
            <a
              href={"/trips"}
              className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={() =>
                mixpanel.track("Clicked Menu Item", {
                  itemClicked: "My Pickups",
                })
              }
            >
              My Pickups
            </a>
          </li>
        )}
        {menuElements.map((element) => (
          <li
            key={element.text}
            className={`mb-2 ${element.hidden && "lg:hidden"}`}
          >
            <a
              href={element.linkTo}
              className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={() => handleMenuItemClick(element.linkTo)}
            >
              {element.text}
            </a>
          </li>
        ))}
        {loggedIn && (
          <a
            href="/"
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
            onClick={handleLogout}
          >
            Log out
          </a>
        )}
      </ul>

      <div className="py-2">
        <a
          href="/"
          className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
        >
          <img
            src="/images/marketing/delivery-truck-side.png"
            className="mr-3 h-4"
            alt="KW Pickup Logo"
          />
          KW Pickup
        </a>
      </div>
    </div>
  );
};

export default NavMenuExpanded;
